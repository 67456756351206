import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import * as S from './styles'

const Footer = () => {
  const { logo } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "colegio-exato-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <S.Footer>
      <S.Logo>
        <Img fluid={logo.childImageSharp.fluid} />
      </S.Logo>
      <S.Phones>(62) 3324-1885 | (62) 3943-5099 | (62) 99157-7371</S.Phones>
      <S.Copy>© 2023. Colégio Exato | Todos direitos resevados</S.Copy>
    </S.Footer>
  )
}

export default Footer
