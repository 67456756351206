import styled from 'styled-components'
import { colors } from '../../utils/global'

export const Footer = styled.footer`
  display: flex;
  background: ${colors.mercury};
  margin: 0 auto;
  padding: 80px 16px;
  align-items: center;
  flex-direction: column;
`

export const Logo = styled.div`
  width: 200px;
  margin-bottom: 20px;
`

export const Copy = styled.p`
  color: ${colors.bayOfMany};
  margin: 16px 0 0;
  text-align: center;
`

export const Phones = styled(Copy)`
  font-weight: 800;
`
