import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import * as S from './styles'

const Navbar = () => {
  const [menuActive, setMenuActive] = useState(false)
  const { logo } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "colegio-exato-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <S.Container>
      <S.Nav>
        <S.Logo>
          <Img fluid={logo.childImageSharp.fluid} />
        </S.Logo>
        <S.MenuWrapper>
          <S.MenuHamburgerWrapper onClick={() => setMenuActive(!menuActive)}>
            <S.MenuHamburger
              className={menuActive && 'active'}
            ></S.MenuHamburger>
          </S.MenuHamburgerWrapper>
          <S.Menu isOpen={menuActive}>
            <S.MenuItem>
              <S.MenuLink to="/" activeClassName="active">
                Home
              </S.MenuLink>
            </S.MenuItem>
            <S.MenuItem>
              <S.MenuLink to="/o-exato" activeClassName="active">
                O Exato
              </S.MenuLink>
            </S.MenuItem>
            <S.MenuItem>
              <S.MenuLink to="/equipe" activeClassName="active">
                Equipe
              </S.MenuLink>
            </S.MenuItem>
            <S.MenuItem>
              <S.MenuLink to="/contato" activeClassName="active">
                Contato
              </S.MenuLink>
            </S.MenuItem>
            <S.MenuItem>
              <S.MenuLink to="/blog" activeClassName="active">
                Notícias
              </S.MenuLink>
            </S.MenuItem>
            <S.MenuItem>
              <S.MenuLink
                to="https://aluno.escolarmanageronline.com.br/colegioexato"
                activeClassName="active"
              >
                Portal do aluno
              </S.MenuLink>
            </S.MenuItem>
          </S.Menu>
        </S.MenuWrapper>
      </S.Nav>
    </S.Container>
  )
}

export default Navbar
