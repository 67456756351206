import styled from 'styled-components'
import { colors } from '../../utils/global'
import { Link } from 'gatsby'

export const Container = styled.header`
  height: 160px;
  box-shadow: 0px 6px 10px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1080px) {
    height: 120px;
  }

  @media (max-width: 940px) {
    height: 80px;
  }
`

export const Nav = styled.nav`
  max-width: 1080px;
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Logo = styled.div`
  width: 160px;

  @media (max-width: 1080px) {
    width: 100px;
  }

  @media (max-width: 940px) {
    width: 80px;
  }
`

export const MenuWrapper = styled.div`
  overflow: hidden;
`

export const MenuHamburgerWrapper = styled.div`
  width: 100px;
  height: 100px;
  cursor: pointer;
  position: relative;

  @media (min-width: 941px) {
    display: none;
  }
`

export const MenuHamburger = styled.div`
  top: 50%;
  left: 10%;
  width: 40px;
  height: 5px;
  background: ${colors.bayOfMany};
  position: absolute;
  transition: 0.5s;
  border-radius: 5px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 40px;
    height: 5px;
    background: ${colors.bayOfMany};
    transition: 0.5s;
    border-radius: 5px;
  }

  &:before {
    top: -10px;
  }

  &:after {
    top: 10px;
  }

  &.active {
    background: ${colors.white};

    &:before {
      top: 0;
      transform: rotate(45deg);
    }

    &:after {
      top: 0;
      transform: rotate(135deg);
      box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
    }
  }
`

export const Menu = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0;

  @media (max-width: 940px) {
    right: -100%;
    position: absolute;
    flex-direction: column;
    background: ${colors.bayOfMany};
    padding: 20px;
    top: 80px;
    width: 100%;
    transition: 0.5s all;
    ${({ isOpen }) => isOpen && 'right: 0;'}
  }
`

export const MenuItem = styled.li`
  line-height: 1;
  margin: 0;
  font-size: 18px;
  text-transform: uppercase;

  &:not(:last-child) {
    margin-right: 32px;
  }

  &:last-child {
    background: ${colors.turbo};
    padding: 12px 16px;
    border-radius: 24px;
  }

  @media (max-width: 1080px) {
    font-size: 17px;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  @media (max-width: 940px) {
    margin: 10px 0;
    &:not(:last-child) {
      margin-right: 0;
    }
    &:last-child {
      a {
        color: ${colors.bayOfMany};
      }
    }
  }
`

export const MenuLink = styled(Link)`
  color: ${colors.bayOfMany};
  text-decoration: none;

  &.active {
    font-weight: 800;
  }

  @media (max-width: 940px) {
    color: ${colors.white};
    white-space: nowrap;
  }
`
